.markdown {
	text-wrap: balance;
}

.markdown > h1 {
	@apply text-2xl font-bold;
}
.markdown > h2 {
	@apply text-xl font-bold border-b-2 border-gray-200;
}
.markdown > h3 {
	@apply text-lg font-bold;
}
.markdown > h4 {
	@apply text-base font-bold;
}
.markdown > h5 {
	@apply text-sm font-bold;
}
.markdown > h6 {
	@apply text-xs font-bold;
}
.markdown > p {
	@apply text-base;
}
.markdown > ul {
	@apply list-disc;
	width: 100%;
}
.markdown > ol {
	@apply list-decimal;
	width: 100%;
}
.markdown > li {
	@apply ml-4;
	width: 100%;
}
